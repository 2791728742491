<template>
    <div class="content">
        <div class="main">
            <div class="header">
                <el-select v-model="selectValue" placeholder="请选择" @change="selectData">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <p class="tip">往期推荐引擎推广数据【平均点击率10.69%     平均转化率13.21%】</p>

            </div>
            <el-table :data="tableData" border style="width: 99%;margin-top: 30px;" size="medium" class="jd-table"
                :header-cell-style="{ fontWeight: '500', height: '48px', color: '#14141C', background: '#F5F5F5' }"
             
                :cell-style="{ fontSize: '16px', color: '#333333' ,height:'62px'}">
                <el-table-column prop="boothName" label="展位" :resizable="false"></el-table-column>
                <el-table-column prop="lowestBid" align="center" label="最低出价" :resizable="false">
                </el-table-column>
                <el-table-column prop="averageBid" align="center" label="平均出价" :resizable="false"></el-table-column>
                <el-table-column prop="auggestedBid" align="center" label="建议出价" :resizable="false">
                </el-table-column>
            </el-table>


        </div>


    </div>
</template>
<script>
export default {
    data() {
        return {
            cpcdata: [
                {
                    boothName: '站内资源位-无线焦点图',
                    lowestBid: '12.03',
                    averageBid: '30.08',
                    auggestedBid: '45.12'
                },
                {
                    boothName: '站内资源位-PC焦点图',
                    lowestBid: '12.49',
                    averageBid: '31.14',
                    auggestedBid: '46.71'
                }, {
                    boothName: '站内资源位-PC首页通栏',
                    lowestBid: '12.98',
                    averageBid: '32.46',
                    auggestedBid: '48.69'
                }, {
                    boothName: '站内资源位-PC精选',
                    lowestBid: '12.24',
                    averageBid: '30.61',
                    auggestedBid: '45.92'
                }, {
                    boothName: '站内资源位-手机浏览器类',
                    lowestBid: '13.52',
                    averageBid: '33.81',
                    auggestedBid: '50.72'
                }, {
                    boothName: '站内资源位-无线新闻阅读类',
                    lowestBid: '12.86',
                    averageBid: '32.15',
                    auggestedBid: '48.23'
                }, {
                    boothName: '站内资源位-微博',
                    lowestBid: '12.6',
                    averageBid: '31.51',
                    auggestedBid: '47.27'
                },
            ],
            cpmdata: [
                {
                    boothName: '站内资源位-无线焦点图',
                    lowestBid: '601.6',
                    averageBid: '2406.4',
                    auggestedBid: '4331.52'
                },
                {
                    boothName: '站内资源位-PC焦点图',
                    lowestBid: '622.8',
                    averageBid: '2491.2',
                    auggestedBid: '4484.16'
                }, {
                    boothName: '站内资源位-PC首页通栏',
                    lowestBid: '649.2',
                    averageBid: '2596.8',
                    auggestedBid: '4674.24'
                }, {
                    boothName: '站内资源位-PC精选',
                    lowestBid: '612.2',
                    averageBid: '2448.8',
                    auggestedBid: '4407.84'
                }, {
                    boothName: '站内资源位-手机浏览器类',
                    lowestBid: '676.2',
                    averageBid: '2704.8',
                    auggestedBid: '4868.64'
                }, {
                    boothName: '站内资源位-无线新闻阅读类',
                    lowestBid: '643',
                    averageBid: '2572',
                    auggestedBid: '4629.6'
                }, {
                    boothName: '站内资源位-微博',
                    lowestBid: '630.2',
                    averageBid: '2520.8',
                    auggestedBid: '4537.44'
                },
            ],
            options: [
                {
                    label: '按点击付费CPC',
                    value: 0
                },
                {
                    label: '按展现付费CPM',
                    value: 1
                },
            ],
            tableData:[],
            selectValue: 0

        }
    },
    methods:{
        selectData(){
            if(this.selectValue==0){
                this.tableData=this.cpcdata
            }
            if(this.selectValue==1){
                this.tableData=this.cpmdata
            }
        }
    },
    mounted(){
        this.tableData=this.cpcdata
    }
    
}
</script>
<style scoped lang="scss">
.content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;

    // background-color: rgb(170, 37, 37);
    .main {
        width: 100%;
        height: 80vh;
        box-sizing: border-box;
        padding: 30px;
        background-color: #fff;
    }
}

.header {
    display: flex;
    justify-content: space-between;
}

.tip {
    width: 445px;
    height: 17px;
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    line-height: 40px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
</style>